.btn-primary:not(:disabled):not(.disabled).active:focus,
.btn-primary:not(:disabled):not(.disabled):active:focus,
.show>.btn-primary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 220, 3, 0.5);
}

.btn-primary {
    color: #5a5a5a;
    background-color: #FFDC03;
    border-color: #FFDC03;
}

.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:hover,
.btn-primary:active,
.btn-primary:focus {
    color: #5a5a5a;
    background-color: #efcd00;
    border-color: #efcd00;
    box-shadow: 0 0 0 0.2rem rgba(255, 220, 3, 0.5);
}

.btn-secondary {
    color: #fff;
    background-color: #5a5a5a;
    border-color: #939393;
}

.btn-secondary:not(:disabled):not(.disabled):active,
.btn-secondary:hover,
.btn-secondary:active,
.btn-secondary:focus {
    color: #fff;
    background-color: #6f6f6f;
    border-color: #939393;
}

.form-control:focus {
    border-color: #ffdc03bf;
    box-shadow: 0 0 0 0.2rem rgba(255, 220, 3, 0.25);
}

h3 {
    color: #5a5a5a;
    margin-bottom: .5rem;
    background-color: #FFDC03;
    padding: 10px 10px;
    border-radius: .25rem;
}