.timetable-logo {
    margin-top: 25px;
    max-width: 325px;
}

.table td,
.table th {
    padding: .5rem;
}

.month-buttons {
    margin: 1rem 0 .5rem 0;
}

.btn-link {
    margin-top: 15px;
    color: #5a5a5a;
}

.btn-link:hover {
    color: #5a5a5a;
    text-decoration: underline;
}